import React from 'react'
import { addToCart } from '../../redux/CartSlice'
import {Link} from 'react-router-dom'
import './CarouselItem.css'
import { useDispatch } from 'react-redux';
import { message} from 'antd';
import {getProduct, getSimilarProducts} from "../../redux/ProductSlice";

const CarouselItem = ({data}) => {


	const dispatch = useDispatch()
    const [messageApi, contextHolder] = message.useMessage();

    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'Товар был добавлен в корзинку!',
        });
    };


    const addProductToCart = () => {
        const product = {
            productId: data?.id,
            id: Date.now(),
            name: data?.title,
            price: data?.price,
            quantity: 1,
            img: data?.product_images[0]?.image,
            color: data.colors ? data?.colors[0]?.color?.color_code : null,
            size: 'L'
        }
        dispatch(addToCart(product))
        success()
    }
  return (
	<div className="carousel_item_wrapper">
        {contextHolder}
		<Link to={`/product/${data.id}`} style={{textDecoration: 'none', color: 'black'}}>
			<div onClick={()=> {dispatch(getProduct(data.id)); dispatch(getSimilarProducts(data?.id))}}>
                <div className='popular'>ХИТ</div>
                <img src={data?.product_images[0]?.image} alt={data.title} className="carousel_img" />
                <div className="carousel_price">{data?.price} cом</div>
                <div className="carousel_name">{data?.title}</div>
            </div>

			{/* <div className="carousel_description">{data?.description}</div> */}
		</Link>
		<button className="carousel_btn" onClick={() => addProductToCart()}> &nbsp;
		В корзину
		</button>
	</div>
  )
}

export default CarouselItem