import React from 'react'
import './CatalogueSidebar.css'
import {Col, Layout, Menu, Row, theme} from 'antd';
import { useSelector } from 'react-redux';
const {  Content,  Sider } = Layout;



const CatalogueSidebar = ({onMenuChangeHandler}) => {

	const state_categories = useSelector(state => state.categories.categories)

	const items2 = state_categories?.map(
		(it) => {
			return {
			key: `${it.id}`,
			label: ` ${it.name}`,
			value: `${it.id}`,

			children: it?.child_category?.map((el) => {
				return {
				key: el.id,
				label: `${el.name}`,
                value: `${el.id}`,
				};
			}),
			};
		});



const {
	token: { colorBgContainer },
} = theme.useToken();

  return (
	<div>
		<h2 className='catalogue_header'>Категории</h2>
	<Layout>
		<Content style={{width: '100%', background:'gold'}}>
			<Layout style={{ background: colorBgContainer }}>
                <Row>
                    <Col span={24} style={{ background: colorBgContainer}}
                           onClick = {e => onMenuChangeHandler(e)}
                           className={'catalogue_sider'}

                    >
                        <Menu
                            mode="inline"
                            defaultSelectedKeys={['1']}
                            defaultOpenKeys={['sub1']}
                            style={{ height: '100%' }}
                            items={items2}
                            onClick = {e => onMenuChangeHandler(e)}
                        />
                    </Col>
                </Row>

			</Layout>
		</Content>
	</Layout>
	</div>
  )
}

export default CatalogueSidebar