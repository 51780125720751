import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { publicRequest } from '../utils';
import {toast} from 'react-toastify'

const initialState = {
	categories: [],
    category: {},
	loading: false,
	
}


export const getAllCategories = createAsyncThunk(
	'categories/getAllCategories',
	async() => {
		try {
			const {data} = await publicRequest.get('/category/')
			return data
		} catch (error) {
			console.log(error)
		}
	}
)

export const getCategory = createAsyncThunk(
    'categories/getCategory',
    async(id) => {
        try {
            const {data} = await publicRequest.get(`/category/${id}/`)
            return data
        } catch (error) {
            console.log(error)
        }
    }
)



export const categorySlice = (createSlice({
	name: 'categories',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAllCategories.pending, state => {
				state.loading = true
			}) 
			.addCase(getAllCategories.fulfilled, (state, action) => {
				state.loading = false
				state.categories = action.payload
			}) 
			.addCase(getAllCategories.rejected, state => {
				state.loading = false
			})

            .addCase(getCategory.pending, state => {
                state.loading = true
            })
            .addCase(getCategory.fulfilled, (state, action) => {
                state.loading = false
                state.category = action.payload
            })
            .addCase(getCategory.rejected, state => {
                state.loading = false
            })
	}
}))


export default categorySlice.reducer