import React from 'react'
import './CategoryItem.css'
import {Link} from 'react-router-dom'
import { useSelector } from 'react-redux';



const CategoryItem = ({data}) => {

	return (
	<div className='category_wrapper'>
		{
        <Link to={`/catalogue`} style={{ textDecoration: 'none' }}>
            <div  className='categoryItem'>
                <div className="categoryTitle">{data?.name}</div>
                <img src={data?.photo} alt={data?.name} className="categoryImg" />
            </div>

        </Link>
		}	
	</div>
  )
}

export default CategoryItem
