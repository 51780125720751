import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { publicRequest } from '../utils';
import {toast} from 'react-toastify'

const initialState = {
	products: [],
	product: {},
    similarProducts: [],
	loading: false,
    productsCollection: [],
    categoriesCollection: [],
}


export const getAllProducts = createAsyncThunk(
	'products/getAllProducts',
	async() => {
		try {
			const {data} = await publicRequest.get('/products/')
			return data
		} catch (error) {
			console.log(error)
		}
	}
)

export const getSimilarProducts = createAsyncThunk(
    'products/getSimilarProducts',
    async(id) => {
        try {
            const {data} = await publicRequest.get(`/similar/products/${id}/`)
            return data
        } catch (error) {
            console.log(error)
        }
    }
)

export const getProduct = createAsyncThunk(
	'products/getProduct',
	async(id) => {
		try {
			const {data} = await publicRequest.get(`/products/${id}/`)
			return data
		} catch (error) {
			console.log(error)
		}
	}
)

export const filterProductsByCategory = createAsyncThunk(
	'products/filterProducts',
	async(id) => {

		try {
			const {data} = await publicRequest.get(`/product/by_category/${id}/`)
			return data
		} catch (error) {
			console.log(error)
		}
	}
)

export const getSearchedProducts = createAsyncThunk(
    'products/Search',
    async(name) => {

        try {
            const {data} = await publicRequest.get(`/search/?search=${name}`)
            return data
        } catch (error) {
            console.log(error)
        }
    }
)

export const getProductsCollection = createAsyncThunk(
    'products/Collections',
    async() => {
        try {
            const {data} = await publicRequest.get(`/collections/`)
            return data
        } catch (error) {
            console.log(error)
        }
    }
)

export const getCategoriesCollection = createAsyncThunk(
    'products/Category-Collections',
    async() => {
        try {
            const {data} = await publicRequest.get(`/category-collections/`)
            return data
        } catch (error) {
            console.log(error)
        }
    }
)


export const productSlice = (createSlice({
	name: 'products',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAllProducts.pending, state => {
				state.loading = true
			}) 
			.addCase(getAllProducts.fulfilled, (state, action) => {
				state.loading = false
				state.products = action.payload
			}) 
			.addCase(getAllProducts.rejected, state => {
				state.loading = false
			})


            .addCase(getSimilarProducts.pending, state => {
                state.loading = true
            })
            .addCase(getSimilarProducts.fulfilled, (state, action) => {
                state.loading = false
                state.similarProducts = action.payload
            })
            .addCase(getSimilarProducts.rejected, state => {
                state.loading = false
            })


            .addCase(getProduct.pending, state => {
				state.loading = true
			}) 
			.addCase(getProduct.fulfilled, (state, action) => {
				state.loading = false
				state.product = action.payload
			}) 
			.addCase(getProduct.rejected, state => {
				state.loading = false
			})


			 .addCase(filterProductsByCategory.pending, state => {
				state.loading = true
			}) 
			.addCase(filterProductsByCategory.fulfilled, (state, action) => {
				state.loading = false
				state.products = action.payload
			}) 
			.addCase(filterProductsByCategory.rejected, state => {
				state.loading = false
			})



            .addCase(getSearchedProducts.pending, state => {
                state.loading = true
            })
            .addCase(getSearchedProducts.fulfilled, (state, action) => {
                state.loading = false
                state.products = action.payload
            })
            .addCase(getSearchedProducts.rejected, state => {
                state.loading = false
            })


            .addCase(getProductsCollection.pending, state => {
                state.loading = true
            })
            .addCase(getProductsCollection.fulfilled, (state, action) => {
                state.loading = false
                state.productsCollection = action.payload
            })
            .addCase(getProductsCollection.rejected, state => {
                state.loading = false
            })

            .addCase(getCategoriesCollection.pending, state => {
                state.loading = true
            })
            .addCase(getCategoriesCollection.fulfilled, (state, action) => {
                state.loading = false
                state.categoriesCollection = action.payload
            })
            .addCase(getCategoriesCollection.rejected, state => {
                state.loading = false
            })
	}
}))


export default productSlice.reducer
