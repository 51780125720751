import React, {useEffect, useState} from 'react'
import './Header.css'
import phone from '../../images/phone_icon.svg'
import logo from '../../images/logo.svg'
import s from '../../commonStyles.module.css'
import cart from '../../images/Group 830.png'
import category from '../../images/category.png'
import search from '../../images/glass.svg'
import {Link, useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux';
import { Select } from 'antd';
import {filterProductsByCategory, getSearchedProducts} from "../../redux/ProductSlice";
import { updateActiveCategory, updateActiveSubcategory } from '../../redux/CartSlice'
import { getCategory } from '../../redux/CategorySlice'


const Header = ({onMenuChangeHandler}) => {
    const dispatch = useDispatch()
    const categories = useSelector(state => state.categories.categories)
    const cart_quantity = useSelector(state => state.cart.cart.length)
    const products = useSelector(state => state.products.products)
    const [searchedProduct, setSearchedProduct] = useState()
    const navigate = useNavigate();

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const searchProduct = (value) => {
        setSearchedProduct(value)
    }

    const onChange = async (value) => {
        if(value.key === 'Enter'){
            try{
                const res = await dispatch(getSearchedProducts(searchedProduct))
                if(res.payload){
                    navigate('/catalogue');
                    onMenuChangeHandler({id: res.payload[0].category, type: 'search'})
                }
            }catch (err){
                console.log(err)
            }
        }
    };


    const onCategoryChangeHandler = async (value) => {
        dispatch(filterProductsByCategory(value))
          let target = categories.find(it => it.id === value)

          if(target){
              let targetSubcategory = target.child_category.find(it => it.id === value)
              dispatch(updateActiveCategory({name: target.name, id: target.id}))
              dispatch(updateActiveSubcategory({name: targetSubcategory.name, id: targetSubcategory.id}))
          } else {
              let res = await dispatch(getCategory(value))
              dispatch(updateActiveSubcategory({name: res.payload.name, id: res.payload.id}))
          }
    }

  return (
	<div className='header'>
        {
            windowDimensions.width > 800
                ? <section className='top_header'>
                        <div className='phone'>
                            <div className='top_img'>
                                <img src={phone} className='phone_icon' alt='phone'/>
                            </div>
                            <div className='top_phone'>+7 (3) 314 3214</div>
                        </div>
                        <div className='top_menu'>
                            <div>
                                <div className='top_menu_item'>FAQ</div>
                                <div className='top_menu_item'>О нас</div>
                                <div className='top_menu_item'>Контакты</div>
                                <div className='top_menu_item'>Доставка</div>
                            </div>
                        </div>
                    </section>
                : <section className='top_header'>
                    <Link to='/' className='logo'>
                        <img src={logo} className='logo'/>
                    </Link>
                    </section>
        }
		<section className='main_header'>
			<div className='search_block'>
				<Link to='/' className='logo'>
				    <img src={logo} className='logo'/>
				</Link>
                <div className='search'>
					<img src={search} className='search_icon'/>
					<Select
                        showSearch
					    className='search_input'
                        onKeyDown={value => onChange(value)}
                        bordered={false}
                        onSearch={value => searchProduct(value)}

						options={
							products?.map(it => ({   
								    value: it?.name,
									label: (
										<div style={{display: 'flex', color: 'black'}}>
											<div>
                                                { Array.isArray(it?.product_images) && <img src={it?.product_images[0]?.image} className='optionImg'/>}
                                            </div>
											<div className='optionText'>
												<div>{it?.title}</div>
												<div style={{fontWeight: 'bold'}}>{it?.price} P</div>
											</div>
										</div>
									)		
								}
								))
							}
						>
  					</Select>
				</div> 
				<div className='header_items'>
					<div className='catalogue'>
						<Link to='/catalogue' className={s.button} style={{textDecoration: 'none', color: 'black'}}>
							<img src={category} className='button_icon'/>
							Каталог
                        </Link>
						<Link to='/cart' className={s.button} style={{textDecoration: 'none', color: 'black'}}>
							{cart_quantity > 0? <div className='cart_info'>{cart_quantity}</div> : null}
							<img src={cart} className='button_icon'/>
							Корзина
                        </Link>
					</div>
				</div>
			</div>
			
			
			<section className='categories'>
				{
					categories
					?.filter((it, index) => (index <= 8))
					?.map((el, index) =>
						<Link to={`/catalogue`}
						style={{textDecoration: 'none', color: 'black'}}
						className="category_item"
                        key={index}
                        >
                            <div onClick={() => onCategoryChangeHandler(el.id)}>
                                {el?.name}
                            </div>
                        </Link>
					)
				}
				<Link to={`/catalogue`} className="category_item" style={{fontSize: '20px', width: '50px', textDecoration: 'none', color: 'black'}}>...</Link>
			</section>
			</section>
	</div>
  )
}

export default Header