
import './App.css';
import Home from './Pages/Home/Home';
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Catalogue from './Pages/Catalogue/Catalogue';
import CategoryList from './Pages/CategoryList/CategoryList';
import ProductDetail from './Pages/ProductDetail/ProductDetail';
import Cart from './Pages/Cart/Cart';
import ServiceDetail from './Pages/ServiceDetail/ServiceDetail';
import {getCategoriesCollection, getProductsCollection} from "./redux/ProductSlice";
import {useEffect} from "react";
import {useDispatch} from "react-redux";



function App() {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getProductsCollection())
        dispatch(getCategoriesCollection())
    }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
           <Route path="/" element={<Home/>} />
            <Route path="/catalogue" element={<Catalogue/>} />
            <Route path="/category/:id" element={<CategoryList/>} />
            <Route path="/product/:id" element={<ProductDetail/>} />
            <Route path="/service/:id" element={<ServiceDetail/>} />
            <Route path="/cart" element={<Cart/>} />
       </Routes>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
         />
      </div>
    </BrowserRouter>
    
  );
}

export default App;
