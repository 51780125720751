import React from 'react'
import CarouselItem from '../CarouselItem/CarouselItem';
import './PopularProducts.css'
import Carousel from 'better-react-carousel';

const PopularProducts = ({products}) => {
      return (
        <section className='popular_products_wrapper'>
            <div className='popular_items'>
             <div className="carousel_wrapper">
          <Carousel cols={5} rows={1} gap={5} loop >
          {
             Array.isArray(products) && products.map(el =>
                              <Carousel.Item key={el.id}>
                                    <CarouselItem data={el} key={el?.id}/>
                              </Carousel.Item>
                          )
          }
        </Carousel>
        </div>
            </div>
        </section>
      )
}

export default PopularProducts