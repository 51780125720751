import React, { useEffect, useState } from 'react'
import Header from '../../Components/Header/Header';
import './ProductDetail.css'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import './ProductDetail.css'
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import {useSelector} from 'react-redux';
import Footer from '../../Components/Footer/Footer'
import {Link} from 'react-router-dom'
import CarouselItem from "../../Components/CarouselItem/CarouselItem";
import ScrollToTop from "../../Components/ScrollToTop";
import PopularProducts from "../../Components/PopularProducts/PopularProducts";
import MobileHeader from '../../Components/MobileHeader/MobileHeader';


const ProductDetail = () => {
    const similar_products = useSelector(state => state.products.similarProducts)
    const product = useSelector(state => state.products.product)


	  function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
  

  return (
	<div className='product_detail_container'>
		<Header />
		{
            windowDimensions.width < 1000
                ? <MobileHeader />
                : null
        }

		
		<div className='productDetail'>
			<div className='slider_block'>
				<section className='slider'>
					<ImageGallery 
						thumbnailPosition='left'
                        items = {product?.product_images?.map((el => ({
                                                            original: el?.image,
                                                            thumbnail: el?.image
                                                    })))}
						showFullscreenButton={false}
						showPlayButton={false}
						showNav={false}
						/>
					</section>
					<section className='product_detail_text'>
						<h2>{product.title}</h2>
						<div className="product_detail_price"> {product.price} P</div>
						<div className='product_features'>Описание товара</div>
						<div className='product_weight'>
							<div className='product_weight_label'>
								Вес/Объем/Размер:
							</div>
							<div className='product_weight_value'>{product.size}</div>
						</div>
						<div className='product_weight'>
							<div className='product_weight_label'>
								Цвет:
							</div>
							<div className='product_weight_value'>{product.color}</div>
						</div>
						<div style={{marginTop: '40px'}}>
							<Link to='/cart' className='product_detail_btn' style={{textDecoration: 'none', fontSize:'14px'}}>В корзинy</Link>
						</div>			
					</section>
				</div>
				<div className='product_detail_description'>
					<div className='product_detail_subheader'>Описание</div>
					<div dangerouslySetInnerHTML={{__html: product?.description}}>
					</div>
				</div>
			</div>
        <h2 className={'h2'} style={{margin: '20px auto', width: '80%'}}>Похожие товары</h2>
           <div className="similar_products">
               <PopularProducts products={similar_products}/>
               {/*{similar_products?.map(it => (*/}
               {/*    <CarouselItem data={it}/>*/}
               {/*))}*/}
           </div>
			<Footer />
            <ScrollToTop />
	</div>
  )
}

export default ProductDetail
