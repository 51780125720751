import React from 'react';
import  './MobileHeader.css'
import {AppstoreOutlined, HomeOutlined, MenuOutlined, ShoppingCartOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";

const MobileHeader = (props) => (
    <div className='mobile_header'>
        <Link to={'/'} className="mobile_header_item">
            <div className="mobile_header_img">
                <HomeOutlined className={'mobile_header_icon'}/>
            </div>
            <div className="mobile_header_text">Главная</div>
        </Link>
        <Link to={'/catalogue'} className="mobile_header_item">
            <div className="mobile_header_img">
                <AppstoreOutlined className={'mobile_header_icon'}/>
            </div>
            <div className="mobile_header_text">Каталог</div>
        </Link>
        <Link to={'/cart'} className="mobile_header_item">
            <div className="mobile_header_img" >
                <ShoppingCartOutlined className={'mobile_header_icon'} />
            </div>
            <div className="mobile_header_text">Корзина</div>
        </Link>
        <div className="mobile_header_item">
            <div className="mobile_header_img">
                <MenuOutlined className={'mobile_header_icon'}/>
            </div>
            <div className="mobile_header_text">Меню</div>
        </div>
    </div>

);

export default MobileHeader;