import React, {useEffect, useState} from 'react'
import Header from '../../Components/Header/Header'
import './Cart.css'
import CartItem from '../../Components/CartItem/CartItem';
import Footer from '../../Components/Footer/Footer';
import { useSelector } from 'react-redux';
import {  Modal } from 'antd';
import {useDispatch} from "react-redux";
import { resetCart } from '../../redux/CartSlice';
import { ShoppingCartOutlined } from '@ant-design/icons';
import {createOrder} from "../../redux/OrderSlice";
import ScrollToTop from "../../Components/ScrollToTop";
import PopularProducts from "../../Components/PopularProducts/PopularProducts";
import banner from '../../images/Баннер.svg'
import MobileHeader from '../../Components/MobileHeader/MobileHeader';


const Cart = () => {
    const dispatch = useDispatch()
	const cart = useSelector(state => state.cart.cart)
    const total_quantity = useSelector(state => state.cart.total_quantity)
    const total_sum = useSelector(state => state.cart.total_sum)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [phone, setPhone] = useState()
    const [username, setUsername] = useState()
    const [address, setAddress] = useState()
    const [comment, setComment] = useState()
    const [secondModalOpen, setSecondModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

  
    const makeOrder = async (e) => {
        e.preventDefault()
        const res = await dispatch(createOrder(
                {
                    products: cart.map(it => ({
                        product: it.productId,
                        size: it?.size,
                        color: it?.color,
                        price: Number(it?.price),
                        quantity: it?.quantity
                    })),
                        phone_number: phone,
                        name: username,
                        address,
                        // comment,
                }
        ))
       if(res){
           dispatch(resetCart())
           setIsModalOpen(false);
           setSecondModalOpen(true)
       }

    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

      function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

  return (
	<div>
		<Header/>
        {
            windowDimensions.width < 1000
                ? <MobileHeader />
                : null
        }
		<h2 className='cart_header' style={{width: '80%', margin: '15px auto'}}>Корзина</h2>
		<div className='cart'>
			<div className='cart_items_wrapper'>
				{ cart.length > 0
					? cart.map(it => (
						<CartItem data={it} key={it.id}/>
					))
                    : <div className='emptyCart'> <ShoppingCartOutlined /> &nbsp; Корзина покупок пуста...</div>
				}
			</div>
			<div className='cart_form'>
                <div className="cart_banner">
					<img src={banner} className="cart_banner_img"/>
				</div>
				{cart.length > 0 ?
                 <div className="cart_details">
					<div className="cart_details_title">
						Сумма заказа
					</div>
					<div className="cart_detail_item">
						<div>{
                            cart?.map(it =>
                                <div style={{paddingBottom: '15px'}}>
                                    <div className={'cart_order'}>
                                        <div>{it?.name} &nbsp; ({it?.quantity} шт)</div>
                                        <div>{it?.price * it?.quantity} P</div>
                                    </div>
                                </div>
                                )
                             }

                            <div className="cart_detail_total" style={{fontWeight: '600'}}>
                                <div>Итого:</div>
                                <div>{total_quantity} шт</div>
                                <div>{total_sum} P</div>
                            </div>
                        </div>

                    </div>
				</div>
                : null}
                <Modal
                    width={350}
                    title="Оставьте ваши контакты"
                    open={isModalOpen}
                    onCancel={handleCancel}
                    cancelButtonProps={{style: { display: 'none' }}}
                    okButtonProps={{ style: { display: 'none' } }}
                >

                    <div className={'cart_modal'}>
                        <div style={{display: 'flex', textAlign: 'center', margin: '15px auto', color: "black"}} >
                            <div >Ваш заказ:</div>
                            <div > &nbsp; <strong>{total_quantity} шт</strong> на <strong>{total_sum} P</strong></div>
                        </div>

                        <div>Ваш номер телефона</div>
                        <input className={'cart_modal_input'}
                               onChange={e => setPhone(e.target.value)}
                        />
                    </div>
                    <div className={'cart_modal'}>
                        <div>Ваше имя</div>
                        <input
                            className={'cart_modal_input'}
                            onChange={e => setUsername(e.target.value)}
                        />
                    </div>
                    <div className={'cart_modal'}>
                        <div>Ваш адрес</div>
                        <input
                            className={'cart_modal_input'}
                            onChange={e => setAddress(e.target.value)}
                        />
                    </div>
                    <div className={'cart_modal'}>
                        <div>Комментарий</div>
                        <textarea className={'cart_modal_input'}
                                  onChange={e => setComment(e.target.value)}
                        />
                    </div>
                    <button className='modal_btn' onClick={(e) => makeOrder(e)}>Оформить заказ</button>

                </Modal>
                <Modal
                    width={350}
                    open={secondModalOpen}
                    onCancel={() =>  setSecondModalOpen(false)}
                    cancelButtonProps={{style: { display: 'none' }}}
                    okButtonProps={{ style: { display: 'none' } }}
                >
                    <div className='secondModal'>
                        <div>
                            <img src='/uploads/CheckCircle.svg' style={{width: '70px', height: '70px'}}/>
                        </div>
                        <div style={{color: 'black'}}><strong>Спасибо! За ваш заказ!</strong> </div>
                        <div>Мы перезвоним как можно скорее</div>
                        <button className='modal_btn' onClick={() =>  setSecondModalOpen(false)}>Закрыть</button>
                    </div>
                </Modal>
				{cart.length > 0 ? <button className='product_detail_btn' onClick={showModal}>Оформить заказ</button> : null}
                {/*<button onClick={() => dispatch(resetCart())}>Очистить корзину</button>*/}
            </div>
		</div>
		<h2 className='cart_header' style={{width: '80%', margin: '15px auto'}}>Также может вам понравиться</h2>
		<PopularProducts />
		<Footer />
        <ScrollToTop />
	</div>
  )
}

export default Cart