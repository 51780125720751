
import React, {useState, useEffect} from 'react'
import Header from '../../Components/Header/Header'
import './CategoryList.css'
import CatalogueSidebar from '../../Components/CatalogueSidebar/CatalogueSidebar';
import { useSelector } from 'react-redux';
import CarouselItem from '../../Components/CarouselItem/CarouselItem';
import Footer from '../../Components/Footer/Footer';
import { Link, useParams } from 'react-router-dom';


const CategoryList = () => {
  const params = useParams()
  const categories = useSelector(state => state.categories.categories)
  const subcategories = useSelector(state => state.subcategories.subcategories)

  const products = (useSelector(state => state.products.products))
  const [activeCategory, setActiveCategory] = useState('')
  const defaultSubcategory = categories.find(it => it?._id === activeCategory?.id)?.subcategories[0]
  
  const [subcategoryProducts, setSubcategoryProducts] = useState(products.filter(it => it.subcategory === defaultSubcategory?._id))
  const [activeSubcategory, setActiveSubcategory] = useState()

    useEffect(() => {
    if(params.id){
       const activeCat = categories.find(it => it._id === params.id)
       setActiveCategory({id: activeCat._id, name: activeCat.name})
       setActiveSubcategory(activeCat.subcategories[0])
      
      const result = products.filter(it => it.category === activeCat._id)
          .filter(el => el.subcategory === activeCat.subcategories[0]._id)
      
      setSubcategoryProducts(result)
    }
     
  }, [params.id])

  const onSubcategoryChangeHandler = (subcategory) => {

    if(subcategory.name && subcategory._id){
        const result = products.filter(it => it.subcategory === subcategory._id)
        let activeCat = categories.find(it  => it._id === result.category)
        if(result){
            setSubcategoryProducts(result)
            setActiveSubcategory({name: subcategory.name, id: subcategory._id})
            setActiveCategory({id: activeCat._id, name: activeCat.name })
        }
    }
    if(subcategory.key){
        const result = products.filter(it => it.subcategory === subcategory.key)
        setSubcategoryProducts(result)
        const targetSubcategory = subcategories?.find(it => it._id === subcategory.key)
        setActiveSubcategory({name: targetSubcategory.subcategory, id: targetSubcategory._id})

        const activeCat = categories.find(it => it.subcategories.find(el => el._id === subcategory.key))
        setActiveCategory({id: activeCat._id, name: activeCat.name})
   }
  }


  const onMenuCategoryChangeHandler = (value) => {
     const target = categories.find(it => it.name === value.target.innerText) 
      setActiveCategory({name: target.name, id: target._id})
      setActiveSubcategory(target.subcategories[0])
      
      const result = products.filter(it => it.category === target._id)
          .filter(el => el.subcategory === target.subcategories[0]._id)
      
      setSubcategoryProducts(result)
  }

  return (
	<div >
    <Header />
    <div className='categoryList'>
       <section className='cateryList_wrapper'>
        <CatalogueSidebar
            onSubcategoryChangeHandler ={onSubcategoryChangeHandler }
            onMenuCategoryChangeHandler = {onMenuCategoryChangeHandler }
        />
        </section>
    <section className='categoryList_content'>
      {
        categories.filter((it) => it._id === activeCategory.id).map((el, index) => (
          <div>
            <h2>{activeCategory.name}</h2>
            <div className='subtitle'>{subcategoryProducts?.length} товар(а) в подкатегории </div>
            <div>{el.subcategories.map(it => 
            <button 
              name={it.name}
              className={activeSubcategory?.name === it.name ? 'active_btn' : 'category_btn'}
              onClick={(e) => onSubcategoryChangeHandler(it)}
              >
                {it.name}
              </button>
            )}</div>
          </div> 
        ))
      }
        <div className='subcategories_product'>
          {subcategoryProducts?.map(it => (
            <Link to={`/product/${it.id}`} style={{textDecoration: 'none', color: 'black'}}>
              <CarouselItem
                data={it}
                key={it?._id}
              />
            </Link> 
              ))}
        </div>
      </section>
      </div>
      <Footer />
    </div>
    )
  }

export default CategoryList