import React, {useEffect, useState} from 'react'
import CategoryItem from '../../Components/Categories/CategoryItem'
import Header from '../../Components/Header/Header'
import s from '../../commonStyles.module.css'
import PopularProducts from '../../Components/PopularProducts/PopularProducts'
import Footer from '../../Components/Footer/Footer';
import {useDispatch, useSelector} from 'react-redux';
import {filterProductsByCategory, getAllProducts} from '../../redux/ProductSlice'
import { getAllCategories, getCategory } from '../../redux/CategorySlice'
import { Link } from 'react-router-dom';
import CategoriesCollection from "../../Components/CategoriesCollection";
import './Home.css'
import MobileHeader from "../../Components/MobileHeader/MobileHeader";
import { updateActiveCategory, updateActiveSubcategory } from '../../redux/CartSlice'
import ResponsiveSlider from '../../Components/ResponsiveSlider/ResponsiveSlider'

const Home = () => {
	const dispatch = useDispatch()
    const productsCollection = useSelector(state => state.products.productsCollection)
    const categoriesCollection = useSelector(state => state.products.categoriesCollection)
    const categories = useSelector(state => state.categories.categories)

     const onCategoryChangeHandler = async (value) => {
        dispatch(filterProductsByCategory(value))
          let target = categories.find(it => it.id === value)

          if(target){
              let targetSubcategory = target.child_category.find(it => it.id === value)
              dispatch(updateActiveCategory({name: target.name, id: target.id}))
              dispatch(updateActiveSubcategory({name: targetSubcategory.name, id: targetSubcategory.id}))
          } else {
              let res = await dispatch(getCategory(value))
              dispatch(updateActiveSubcategory({name: res.payload.name, id: res.payload.id}))
          }
    }


    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
		dispatch(getAllProducts())
		dispatch(getAllCategories())
	}, [dispatch])



    


  return (
	<div className={s.page}>
		<Header />
        <ResponsiveSlider />
        {
            windowDimensions.width < 1000
                ? <MobileHeader />
                : null
        }
        <div className={'categories_block'}>
            {categories?.map((el, index) => <div onClick={() => onCategoryChangeHandler(el.id)}><CategoryItem data={el} key={index}/></div>)}
        </div>
		<Link to='/catalogue' 
			style={{textDecoration: 'none', 
			fontSize: '12px',
			color: '#E21ECA',
			fontWeight: '500',
			letterSpacing: '0.5px',
			margin: '0px 0',
			position: 'absolute',
			right: '10%'
			}}> Смотреть все
        </Link>

         <div>
             <h2 style={{width: '80%', margin: '40px auto'}}>{Array.isArray(productsCollection) && productsCollection[0]?.title}</h2>
             <PopularProducts products={Array.isArray(productsCollection) && productsCollection[0]?.products} />
         </div>
        <h2 style={{width: '80%', margin: '40px auto'}}>{Array.isArray(categoriesCollection) && categoriesCollection[0]?.title}</h2>
        <div >

            <CategoriesCollection 
                onCategoryChangeHandler={onCategoryChangeHandler}
                categories={Array.isArray(categoriesCollection) && categoriesCollection[0]?.categories}/>
        </div>
        <div>
            <h2 style={{width: '80%', margin: '40px auto'}}>{Array.isArray(productsCollection) && productsCollection[1]?.title}</h2>
            <PopularProducts products={Array.isArray(productsCollection) && productsCollection[1]?.products} />
        </div>
        <div>
            <h2 style={{width: '80%', margin: '40px auto'}}>{Array.isArray(productsCollection) && productsCollection[2]?.title}</h2>
            <PopularProducts products={Array.isArray(productsCollection) && productsCollection[2]?.products} />
        </div>
		<Footer />
	</div>
  )
}

export default Home