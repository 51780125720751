import drinks from '../../images/Напитки.svg'

import React from 'react'
import s from './ResponsiveSlider.module.css'
var Carousel = require('react-responsive-carousel').Carousel;
// import "react-responsive-carousel/lib/styles/carousel.min.css"; 



const ResponsiveSlider = () => {
  return (
	<div className={s.slider}>
		<Carousel showArrows={true} 
			// onChange={onChange} 
			// onClickItem={onClickItem} 
			// onClickThumb={onClickThumb}
			showIndicators={false}
			showStatus={false}
			>
                <div className={s.slide}>
                        <div className={s.infoContainer}>
                            <div className={s.title}>Все для яркого семейного застолья</div>
                            <div className={s.desc}>Вкусные национальные напитки подходят к каждому блюду.</div>
                            <button className={s.btn}>К напиткам!</button>
                        </div>
                        <div className={s.imgContainer}>
                            <img className={s.image} src={drinks}/>
                        </div>
                </div>
				<div className={s.slide}>
                        <div className={s.infoContainer}>
                            <div className={s.title}>Все для яркого семейного застолья</div>
                            <div className={s.desc}>Вкусные национальные напитки подходят к каждому блюду.</div>
                            <button className={s.btn}>К напиткам!</button>
                        </div>
                        <div className={s.imgContainer}>
                            <img className={s.image} src={drinks}/>
                        </div>
                </div>
				<div className={s.slide}>
                        <div className={s.infoContainer}>
                            <div className={s.title}>Все для яркого семейного застолья</div>
                            <div className={s.desc}>Вкусные национальные напитки подходят к каждому блюду.</div>
                            <button className={s.btn}>К напиткам!</button>
                        </div>
                        <div className={s.imgContainer}>
                            <img className={s.image} src={drinks}/>
                        </div>
                </div>
              
               
            </Carousel>
	</div>
  )
}

export default ResponsiveSlider