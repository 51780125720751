
import React from 'react'
import '../Components/PopularProducts/PopularProducts.css'
import Carousel from 'better-react-carousel';
import CategoryItem from "./Categories/CategoryItem";

const CategoriesCollection = ({categories, onCategoryChangeHandler}) => {
      return (
        <section className='popular_products_wrapper'>
            <div className='popular_items'>
             <div className="carousel_wrapper">
          <Carousel cols={6} rows={1} gap={10} loop >
          {
             Array.isArray(categories) && categories.map(el =>
        
                   <Carousel.Item key={el.id}>
                    <div onClick={() => onCategoryChangeHandler(el.id)}>
                        <CategoryItem data={el} key={el?.id}/>
                       </div>   
                  </Carousel.Item>
                
              )    
          }
        </Carousel>
        </div>
            </div>
        </section>
      )
}

export default CategoriesCollection


