import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { publicRequest } from '../utils';
import {toast} from 'react-toastify'

const initialState = {
    order: {},
	loading: false
}

export const createOrder = createAsyncThunk(
	'orders/addOrder',
	async (params) => {
		try {
            console.log('params', params)
			const {data} = await publicRequest.post('/order/', params)
			toast.success('Вы сделали заказ')
			return data
		} catch (error) {
			console.log(error)
		}
	}
)


export const orderSlice = (createSlice({
	name: 'orders',
	initialState,
	reducers: {},
	extraReducers: (builder) => {

		builder
            .addCase(createOrder.pending, state => {
				state.loading = true
			}) 
			.addCase(createOrder.fulfilled, (state, action) => {
				state.loading = false
				state.order =  action.payload
			}) 
			.addCase(createOrder.rejected, state => {
				state.loading = false
			}) 

	}
}))

export default orderSlice.reducer