import React, {useEffect, useState} from 'react'
import './Catalogue.css'
import Header from '../../Components/Header/Header';
import CatalogueSidebar from '../../Components/CatalogueSidebar/CatalogueSidebar';
import CategoryItem from '../../Components/Categories/CategoryItem';
import Footer from '../../Components/Footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
import CarouselItem from '../../Components/CarouselItem/CarouselItem';
import { filterProductsByCategory } from '../../redux/ProductSlice';
import {getCategory} from "../../redux/CategorySlice";
import ScrollToTop from "../../Components/ScrollToTop";
import {updateActiveCategory, updateActiveSubcategory} from '../../redux/CartSlice'
import MobileHeader from '../../Components/MobileHeader/MobileHeader';



const Catalogue = () => {
	  const products = (useSelector(state => state.products.products))
	  const categories = useSelector(state => state.categories.categories)
    const dispatch = useDispatch()
    const activeCategory = useSelector(state => state.cart.activeCategory)
    const activeSubcategory = useSelector(state => state.cart.activeSubcategory)

     function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
  


    console.log('activeC', activeCategory, activeSubcategory)

   const onMenuChangeHandler = async (value) => {
      if(value.id && value.type && value.type === 'search'){
          try{
              const res = await dispatch(getCategory(value.id))
              dispatch(updateActiveSubcategory({id: res.payload.id, name: res.payload.name}))

              let target = categories.find(it => it.child_category.find(el => el.id === (value.id)))
              dispatch(updateActiveCategory({name: target.name, id: target.id}))
          }catch (err){
              console.log(err)
          }
      }


      if(typeof(value) === 'number'){
          dispatch(filterProductsByCategory(value))
          let target = categories.find(it => it.id === value)

          if(target){
              let targetSubcategory = target.child_category.find(it => it.id === value)
              dispatch(updateActiveCategory({name: target.name, id: target.id}))
              dispatch(updateActiveSubcategory({name: targetSubcategory.name, id: targetSubcategory.id}))
          } else {
              let res = await dispatch(getCategory(value))
              dispatch(updateActiveSubcategory({name: res.payload.name, id: res.payload.id}))
          }

      }else{
          if(value.key ){
              value.domEvent.stopPropagation()
              dispatch(filterProductsByCategory(Number(value.key)))
              dispatch(updateActiveSubcategory({name: value.domEvent.target.innerHTML, id: Number(value.key)}))
              let target = categories.find(it => it.child_category.find(el => el.id === Number(value.key)))
              dispatch(updateActiveCategory({name: target.name, id: Number(target.id)}))
          }

          if(!value.key){
              const target = categories.find(it => it.name === value.target.innerText)
              dispatch(filterProductsByCategory(target.id))
              dispatch(updateActiveCategory({name: target.name, id: Number(target.id)}))
          }
      }
  }


  return (
	<div className='categories_catalogue'>
		<Header onMenuChangeHandler={onMenuChangeHandler}/>
     {
            windowDimensions.width < 1000
                ? <MobileHeader />
                : null
        }
		<section className='cat_catalogue_content'>
			<section className='catalogue_sidebar'>
				<CatalogueSidebar 
                    onMenuChangeHandler = {onMenuChangeHandler }
                />
			</section>
			{!activeCategory 
			?
      <section className='catalogue_info'>
				<div className='banner'>
					<div className='banner_text_block'>
						<h2>Вкус молока, прям как в детстве</h2>
						<div className='banner_description'>Вкусные национальные напитки подходят каждому блюду</div>
						<button className='bunner_button'>Перейти!</button>
					</div>
					<div className='banner_img_block'>
						<img src={'/uploads/kefir.svg'} className='banner_img'/>
					</div>
					<div className="cyrcle1"></div>
					<div className="cyrcle2"></div>
				</div>
				<div className='categories_block'>
					<CategoryItem 
						style={{
								display: 'grid',
								gridTemplateColumns: 'repeat(3, 1fr)',
								width: '100%',
								height: '100%',
								
						}}	
					/>
				</div>
			</section>
	
		:  <section className='categoryList_content'>
      {
       categories.filter((it) => it.id === activeCategory.id).map(el => (
         <div >
           <h2>{el.name}</h2>
           <div className='subtitle'>{el.child_category?.length} товар(а) в подкатегории </div>
           <div>{el.child_category.map(it =>
           <button
             name={it.name}
             className={activeSubcategory?.name === it.name ? 'active_btn' : 'category_btn'}
             onClick={() => onMenuChangeHandler(it.id)}
             >
               {it.name}
             </button>
           )}</div>
         </div>
       ))
      }
       <div className='subcategories_product'>
         {products?.map(it => (
             <CarouselItem
              data={it}
              key={it?.id}
             />
             ))}
       </div>
      </section>}
	  </section>
		<Footer />
        <ScrollToTop />
	</div>


  )
}

export default Catalogue